// Object: helpers

export default function element(name: string, all: boolean = false) {
	let elm: HTMLCollectionOf<Element> | NodeListOf<Element> | HTMLElement | null = null;

	if (name.substring(0, 1) === ".") {
		elm = document.getElementsByClassName(name.substring(1));
		return elm && all ? elm : elm[0] as HTMLElement;
	} else if (name.substring(0, 1) === "#") {
		elm = document.getElementById(name.substring(1));
		return elm as HTMLElement;
	} else {
		elm = document.getElementsByTagName(name);
		if (elm && elm.length > 0) {
			return all ? elm : elm[0] as HTMLElement;
		} else {
			elm = document.querySelectorAll(name);
			if (elm && elm.length > 0) {
				return all ? elm : elm[0] as HTMLElement;
			}
		}
	}

	if (!elm) {
		console.warn(`'${name}'`, " not found");
		return null;
	}

	return elm;
};
