// Object: helpers

export default function event(element: HTMLCollectionOf<Element> | HTMLElement | NodeListOf<Element> | null, events: string, listener: EventListenerOrEventListenerObject) {

	try {
		if (element !== null) {
			element = element as HTMLElement
			let event = events.split(' ');
			for (let i = 0, iLen = event.length; i < iLen; i++) {
				element.addEventListener(event[i], listener, false);
			}
		}
	} catch (e) {
		console.log(e);
	}

};